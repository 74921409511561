import mova from 'mova';
import en from './en.i18n';
import ru from './ru.i18n';

const langOptions = {
  ru,
  en
};

export default {
  init(lang) {
    mova.setLanguage(langOptions[lang]);
  },
};
